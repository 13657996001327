import React from 'react';

import { version } from '../../../package.json';
import logo from '../../static/logo_inverted.svg';

export default () => {
  return (
    <div className="logo-container" style={{ textAlign: 'center', marginBottom: '2rem' }}>
      <img src={logo} style={{ maxWidth: '75%' }}/>
      <p style={{
        fontSize: '75%',
      }}>v{version}</p>
    </div>
  );
}
