import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { IParticipant } from '../../util/types'
import { getTotalProductQuantity, timestampToHuman } from '../../util/helpers'

interface ITableProps {
  data: IParticipant[];
  onChangePage: (e: any, page: number) => void;
  totalCount: number;
  currentPage: number;
  isLoading: boolean;
}

export default ({ data, onChangePage, totalCount, currentPage, isLoading }: ITableProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[]}
            colSpan={4}
            count={totalCount}
            rowsPerPage={100}
            page={currentPage}
            onChangePage={!isLoading ? onChangePage : (e: any) => e.preventDefault()}
          />
        </TableRow>
        <TableRow>
          <TableCell>Participant ID</TableCell>
          <TableCell align="right">Products</TableCell>
          <TableCell align="right">Name</TableCell>
          <TableCell align="right">Check-in time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((p: IParticipant) => {
          return (
            <TableRow key={p.participantId}>
              <TableCell component="th" scope="row">{p.participantId}</TableCell>
              <TableCell align="right">{p.products && getTotalProductQuantity(p.products)}</TableCell>
              <TableCell align="right">{p.firstName} {p.lastName}</TableCell>
              <TableCell align="right">{p.checkinTime && timestampToHuman(p.checkinTime)}</TableCell>
            </TableRow>
          )
        })}

      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[]}
            colSpan={4}
            count={totalCount}
            rowsPerPage={100}
            page={currentPage}
            onChangePage={!isLoading ? onChangePage : (e: any) => e.preventDefault()}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}
