import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/Error/ErrorBoundary';

import './util/i18n';

import './index.css';
import 'typeface-roboto';

ReactDOM.render(<ErrorBoundary><Root/></ErrorBoundary>, document.getElementById('root'));

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
