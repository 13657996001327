import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { IParticipant } from '../../util/types';
import { isUnderAged, timestampToHuman } from '../../util/helpers'

import Dialog from '../Dialog';
import Notification from './Notification';
import ProductList from './ProductList';

interface ParticipantInfoProps extends IParticipant, WithTranslation {
  lang?: string;
  onAcceptTAC: () => any;
  onBack: () => any;
}

const ParticipantInfo = (props: ParticipantInfoProps) => {

  if (!props.acceptedTAC) {
    return <Dialog show={!props.acceptedTAC}
                   onClose={props.onAcceptTAC}
                   large={true}
                   title={props.t('termsAndConditionsTitle', { lng: props.lang })}
                   content={props.t('termsAndConditionsText', { lng: props.lang })}
                   buttonText={props.t('acceptButtonText', { lng: props.lang })}
    />
  }

  return (
    <div className="participant-info">
      <div className="upside-down">
        <div className="upside-down-content">
          <Notification show={!!props.checkinTime} text={props.t('alreadyCheckedInText', {
            lng: props.lang,
            checkedInTime: timestampToHuman(props.checkinTime)
          })}/>
          <Notification show={isUnderAged(props.birthDay)} text={props.t('underAgedText', { lng: props.lang })}/>

          <div className="info-item">
            <label>{props.t('nameLabel', { lng: props.lang })}</label>
            <div className="info-item-content">{props.firstName} {props.lastName}</div>
          </div>

          <div className="info-item">
            <label>{props.t('startGroupLabel', { lng: props.lang })}</label>
            <div className="info-item-content">{props.startGroup}</div>
          </div>

          <ProductList products={props.products} lang={props.lang}/>

        </div>
        <div className="upside-down-actions">
          <Button variant="contained" color="secondary" onClick={props.onBack}>
            <Icon className="fas fa-angle-left"/>
            {props.t('backButtonText', { lng: props.lang })}
          </Button>
        </div>
      </div>

      <div className="user-message">
        <Typography variant="h5"><span dangerouslySetInnerHTML={{__html: props.t('waitForCustomerService', { lng: props.lang })}}/></Typography>
      </div>
    </div>
  );
}

export default withTranslation()(ParticipantInfo)
