import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';


export default (props: RouteComponentProps) => {
  return (
    <div className="container">
      <h1>404</h1>
      <div className="content">
        <p>Not found :(</p>
        <p><Link to='/'>Home</Link></p>
      </div>
    </div>
  );
}
