import React from 'react';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import db from '../../util/db';
import { LANGUAGES } from '../../util/helpers';

interface ICreateEventProps extends RouteComponentProps {
}

interface ICreateEventState {
  [key: string]: any,

  name: string,
  lang: string,
}

export default class CreateEvent extends React.Component<ICreateEventProps, ICreateEventState> {
  state: ICreateEventState = { name: '', lang: '' }

  handleInputChange = (e: any) => {
    const key = e.target.name;
    return this.setState({ [key]: e.target.value });
  }

  isPristine = () => {
    const { name, lang } = this.state;
    return name === '' || lang === '';
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    const { name, lang } = this.state;
    const id = await db.events.add({ name, lang, isExported: false, created: new Date().getTime() });

    if (id) {
      return navigate('/events/' + id);
    }
  }

  render() {
    const { name, lang } = this.state;

    return (
      <div className="create-event">
        <form className='create-event-form' onSubmit={this.handleSubmit}>

          <TextField
            fullWidth
            id="event-name"
            label="Event name"
            name="name"
            value={name}
            onChange={this.handleInputChange}
            margin="normal"
            variant="outlined"
          />

          <TextField
            select
            fullWidth
            name="lang"
            id="event-lang"
            label="Language"
            value={lang}
            onChange={this.handleInputChange}
            SelectProps={{
              native: true,
            }}
            margin="normal"
            variant="outlined">
            <option value=""/>
            {LANGUAGES.map((lang: string, i: number) => {
              return (
                <option key={i} value={lang}>{lang}</option>
              );
            })}
          </TextField>

          <Grid container spacing={40} alignItems="center" style={{ marginTop: '1rem' }}>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <Button
                onClick={() => navigate('/')}>
                <Icon className="fas fa-chevron-left" style={{ fontSize: '.8rem', marginRight: '.4rem' }}/> Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={this.isPristine()}
              >
                Save
              </Button>
            </Grid>
          </Grid>

        </form>
      </div>
    );
  }
}
