import React from 'react';
import { IProduct } from '../../util/types'
import { WithTranslation, withTranslation } from 'react-i18next'

interface IProductListProps extends WithTranslation {
  products: IProduct[];
  lang?: string;
}

const ProductList = ({ products, t, lang }: IProductListProps) => {
  if (!products.length) {
    return null;
  }

  return (
    <div className="info-item">
      <label>{t('productsLabel', { lng: lang })}</label>
      {products.map((p: IProduct) => {
        return (
          <div key={p.id} className="info-item-content multiple">
            <span className="name">{p.cnt} x {p.name}</span> <span className="variations">{p.option}</span>
          </div>
        );
      })}
    </div>
  );
}

export default withTranslation()(ProductList);
