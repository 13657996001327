import React from 'react';

interface INotificationProps {
  show: boolean;
  text: string;
}

export default ({ show, text }: INotificationProps) => {
  if (!show) {
    return null;
  }

  return (
    <div className="notification">
      <i className="fas fa-exclamation-triangle"/> {text}
    </div>
  );
}
