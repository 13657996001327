import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

import diffInYears from 'date-fns/differenceInCalendarYears';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { IParticipant, IProduct, IProductRow, IRow } from './types';

export const dateStrFormat = 'dd-MM-yyyy';
export const dateTimeStrFormat = 'dd-MM-yyyy kk:mm';
export const epokTimeStrFormat = 't';

export const LANGUAGES = [
  'de',
  'en',
  'fi',
  'fr',
  'sv',
]

export const exludedReportKeys = [
  'id',
  'eventId',
  'products',
];

/**
 * Create Theme
 */
export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#674699',
      main: '#391d6a',
      dark: '#0f003f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e91e63',
      contrastText: '#fff',
    },
    background: {
      default: '#0f003f',
      paper: '#0f003f',
    },
    // text: {
    //   primary: '#fff',
    //   secondary: '#fefefe',
    //   disabled: '#ccc',
    //   hint: '#ddd'
    // }
  },
});

/**
 *
 * @param n
 */
const pad = (n: number) => {
  return n < 10 ? '0' + n : n;
}

/**
 *
 * @param stamp
 */
export const timestampToHuman = (stamp: number): string => {
  const d = new Date(stamp);
  const day = d.getUTCDate();
  const month = d.getUTCMonth() + 1;
  const year = d.getFullYear();
  const hour = d.getHours();
  const minute = d.getMinutes();

  return `${day}-${month}-${year} ${pad(hour)}:${pad(minute)}`;
}

/**
 *
 * @param dateStr
 * @param strFormat
 */
export const isUnderAged = (dateStr: string, strFormat: string = dateStrFormat): boolean => {
  const parsed = parse(dateStr, strFormat, new Date());

  return diffInYears(new Date(), parsed) < 18;
}

/**
 * Check if online
 */
export const isOnline = () => navigator.onLine;

/**
 * Check if mobile-safari
 */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Build csv-download
 * @param csv
 */
export const buildCsvURI = (csv: any) => {
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([csv], { type });
  const dataURI = `data:${type};charset=utf-8,${csv}`;

  // @ts-ignore
  const URL = window.URL || window.webkitURL;

  return (typeof URL.createObjectURL === 'undefined')
    ? dataURI
    : URL.createObjectURL(blob);
}

/**
 *
 * @param prods
 */
export const getTotalProductQuantity = (prods: IProduct[]): number => {
  const quantities = prods.map((p: IProduct) => p.cnt);
  return quantities.reduce((a: any, b: any) => (a + b), 0);
}

/**
 * Slugify text
 * @param text
 */
export const slugify = (text: string): string => {
  return text.toString().toLowerCase().trim()
    .replace(/&/g, '-and-')
    .replace(/[\s\W-]+/g, '-')
    .replace(/-$/, '');

}

/**
 * Parse csv-row to appropriate IParticipant
 * @param row
 */
export const parseParticipant = (row: IRow): IParticipant | null => {
  if (row['PARTICIPANT ID'] === '') {
    return null;
  }

  return {
    acceptedTAC: false,
    address: row['ADDRESS'],
    birthDay: row['BIRTHDAY'],
    checkinTime: row['CHECKIN TIME'],
    city: row['CITY'],
    countLevel: row['COUNT LEVEL'],
    countLevelId: row['COUNT LEVELS ID'],
    country: row['COUNTRY'],
    coupon: row['COUPON'],
    couponId: row['COUPONS ID'],
    creationTime: row['CREATION TIME'],
    draftCleanTime: row['DRAFT CLEAN TIME'],
    email: row['EMAIL'],
    eventId: row['EVENTS ID'],
    firstName: row['FIRSTNAME'],
    gender: row['GENDER'],
    lastChanged: row['LAST CHANGED'],
    lastName: row['LASTNAME'],
    notes: row['NOTES'],
    orderId: row['ORDERS ID'],
    participantId: row['PARTICIPANT ID'],
    paymentId: row['PAYMENT ID'],
    postalNumber: row['POSTALNUMBER'],
    price: row['PRICE'],
    priceLevel: row['PRICE LEVEL'],
    priceLevelId: row['PRICE LEVELS ID'],
    productPrice: row['PRODUCTS PRICE'],
    startGroup: row['START GROUP'],
    startGroupId: row['STARTGROUPS ID'],
    status: row['STATUS'],
    tel: row['TEL'],
    team: row['TEAM'],
    type: row['TYPE'],
    products: parseParticipantProducts(row),
  };
}

/**
 *
 * @param row
 */
export const parseParticipantProducts = (row: IRow): IProduct[] => {
  const prodsRow = row['PRODUCTS'];

  if (!prodsRow) {
    return [];
  }

  const prods = JSON.parse(row['PRODUCTS']);

  return prods.map((p: IProductRow): IProduct => {
    return {
      participantId: row['PARTICIPANT ID'],
      name: p.name,
      option: p.option || undefined,
      cnt: Number(p.cnt),
    }
  });
}
