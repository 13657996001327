import Dexie from 'dexie';
import { IEvent, IParticipant, IProduct } from './types'

const DB_NAME = 'RushCheckInApp'
let db: RushDb;

export class RushDb extends Dexie {

  participants!: Dexie.Table<IParticipant, number>;
  events!: Dexie.Table<IEvent, number>;
  products!: Dexie.Table<IProduct, number>;

  constructor() {
    super(DB_NAME);
    db = this;

    this.version(2).stores({
      events: '++id, name, lang, isExported, created',
      participants: '++id, &[eventId+participantId], eventId, participantId',
      products: '++id, [eventId+participantId], eventId, participantId, name, option, cnt',
    });

    this.events = this.table('events');
    this.participants = this.table('participants');
    this.products = this.table('products');

    this.events.mapToClass(Event);
    this.participants.mapToClass(Participant);
  }
}

export class Event {
  id!: number;
  name: string;
  lang: string;
  isExported: boolean;
  created: number;

  constructor({ name, lang, isExported, created }: IEvent) {
    this.name = name;
    this.lang = lang;
    this.isExported = isExported || false;
    this.created = created || new Date().getTime();

    Object.defineProperties(this, {
      participants: { value: [], enumerable: false, writable: true },
    });
  }

  /**
   * Fetch single
   * @param id
   */
  static get(id: number): Promise<IEvent> {
    return db.events.get(id);
  }

  /**
   * Fetch all
   */
  static all(): Promise<IEvent[]> {
    return db.events.toArray();
  }

  /**
   * Delete event & its participants
   * @param id
   */
  static delete(id: number): Promise<boolean[]> {
    return db.transaction('rw', db.events, db.participants, db.products, async () => {
      return Promise.all([
        !!db.events.where('id').equals(id).delete(),
        !!db.participants.where('eventId').equals(id).delete(),
        !!db.products.where('eventId').equals(id).delete()
      ]);
    });
  }

  /**
   * Clear all participants of event
   * @param eventId
   */
  static clearParticipants(eventId: number): Promise<number[]> {
    return db.transaction('rw', db.participants, db.products, async () => {
      return Promise.all([
        db.participants.where({ eventId }).delete(),
        db.products.where({ eventId }).delete()
      ]);
    });
  }
}

export class Participant {
  id?: number;
  eventId?: number;
  acceptedTAC: boolean;
  address: string;
  birthDay: string;
  checkinTime: number;
  city: string;
  countLevel: number;
  countLevelId: number;
  country: string;
  coupon: string;
  couponId: number;
  creationTime: string;
  draftCleanTime: number;
  email: string;
  firstName: string;
  gender: string;
  lastChanged: string;
  lastName: string;
  notes: string;
  orderId: string;
  participantId: string;
  paymentId: string;
  postalNumber: string;
  price: string;
  priceLevel: string;
  priceLevelId: number;
  productPrice: string;
  startGroup: string;
  startGroupId: number;
  status: number;
  tel: string;
  team: string;
  type: number;
  products: IProduct[]

  constructor(props: IParticipant) {
    this.id = props.id;
    this.eventId = props.eventId;
    this.acceptedTAC = props.acceptedTAC;
    this.address = props.address;
    this.birthDay = props.birthDay;
    this.checkinTime = props.checkinTime;
    this.city = props.city;
    this.countLevel = props.countLevel;
    this.countLevelId = props.countLevelId;
    this.country = props.country;
    this.coupon = props.coupon;
    this.couponId = props.couponId;
    this.creationTime = props.creationTime;
    this.draftCleanTime = props.draftCleanTime;
    this.email = props.email;
    this.firstName = props.firstName;
    this.gender = props.gender;
    this.lastChanged = props.lastChanged;
    this.lastName = props.lastName;
    this.notes = props.notes;
    this.orderId = props.orderId;
    this.participantId = props.participantId;
    this.paymentId = props.paymentId;
    this.postalNumber = props.postalNumber;
    this.price = props.price;
    this.priceLevel = props.priceLevel;
    this.priceLevelId = props.priceLevelId;
    this.productPrice = props.productPrice;
    this.startGroup = props.startGroup;
    this.startGroupId = props.startGroupId;
    this.status = props.status;
    this.tel = props.tel;
    this.team = props.team;
    this.type = props.type;
    this.products = props.products || [];

    if (this.id === undefined) {
      delete this.id;
    }
  }

  /**
   * Fetch by eventId & populate results
   * @param eventId
   * @param page
   * @param limit
   */
  static byEventId(eventId: number, page: number = 1, limit: number = 100): Promise<Participant[] | []> {
    return db.transaction('r', db.participants, db.products, async () => {
      const data = await db.participants.where({ eventId }).offset(page * limit).limit(100).toArray();

      if (data.length > 0) {
        return Promise.all(data.map(async (p: IParticipant) => {
          const products = await db.products.where({ 'participantId': p.participantId, eventId }).toArray();
          return new Participant({ ...p, products });
        }));
      }

      return [];
    });
  }

  /**
   * Get total participants count for event
   * @param eventId
   */
  static byEventIdCount(eventId: number): Promise<number> {
    return db.participants.where({ eventId }).count();
  }

  /**
   * Fetch single result & populate products
   * @param participantId
   * @param eventId
   */
  static get(participantId: string, eventId: number): Promise<Participant | null> {
    return db.transaction('r', db.participants, db.products, async () => {
      const data = await db.participants.where({ participantId, eventId }).first();

      if (data) {
        const prods = await db.products.where({ eventId, participantId }).toArray();
        return new Participant({ ...data, products: prods });
      }

      return null;
    });
  }

  /**
   * Save Participant & prods
   */
  save() {
    return db.transaction('rw', db.participants, db.products, async () => {
      this.id = await db.participants.put(this);
      await db.products.bulkAdd(this.products.map((p: IProduct) => ({ ...p, eventId: this.eventId })));

      return this;
    });
  }
}

export default new RushDb();
