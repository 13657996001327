import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router'
import { withTranslation, WithTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { IEvent, IParticipant } from '../../util/types';
import db, { Event, Participant } from '../../util/db';
import ParticipantInfo from './ParticipantInfo';
import Dialog from '../Dialog';
import Logo from '../Logo';

import './checkIn.css';

interface ICheckInProps extends RouteComponentProps, WithTranslation {
  eventId?: string;
}

interface ICheckInState {
  [key: string]: any;

  event?: IEvent;
  participantId?: string;
  participant?: IParticipant;
  dialogOpen: boolean;
  errorMsg: string;
}

const defaultState = {
  event: undefined,
  participantId: '',
  participant: undefined,
  dialogOpen: false,
  errorMsg: ''
}

class CheckIn extends React.Component<ICheckInProps, ICheckInState> {
  state: ICheckInState = defaultState;
  _input?: HTMLInputElement | null;

  async componentDidMount() {
    await this.fetchEvent();
    await this.fetchParticipant();
  }

  fetchEvent = async () => {
    const { eventId } = this.props;

    if (eventId) {
      const event = await Event.get(parseInt(eventId));

      if (!event) {
        return navigate('/404');
      }

      return this.setState({ event });
    }
  }

  handleInputChange = (e: any) => {
    const key = e.target.name;
    const val = e.target.value;

    return this.setState({ [key]: val });
  }

  fetchParticipant = async () => {
    const { eventId, t } = this.props;
    const { participantId, event } = this.state;

    if (eventId && participantId) {
      const participant = await Participant.get(participantId, Number(eventId));

      if (participant) {
        return this.setState({ participant });
      } else {
        return this.setState({
          dialogOpen: true,
          errorMsg: t('participantNotFoundDescription', {
            id: participantId,
            lng: event && event.lang
          })
        });
      }
    }
  }

  setFocus = () => {
    return this._input && this._input.focus();
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    if (this.state.participantId === 'GOBACK') {
      return navigate('/');
    }

    return this.fetchParticipant();
  };

  handleAcceptTAC = async () => {
    const { participant } = this.state;

    if (participant) {
      await db.participants.put({ ...participant, acceptedTAC: true });
      return this.fetchParticipant();
    }
  }

  handleBack = async () => {
    const { participant } = this.state;

    if (participant) {
      // Set checkInTime for possible duplicate check-ins
      db.participants.put({ ...participant, checkinTime: new Date().getTime() });
      return this.resetState();
    }
  }

  resetState = () => {
    const { event } = this.state;
    return this.setState({ ...defaultState, event });
  }


  render() {
    const { t } = this.props;
    const { participantId, participant, dialogOpen, errorMsg, event } = this.state;

    if (!event) {
      return null;
    }

    if (participant) {
      return <ParticipantInfo onAcceptTAC={this.handleAcceptTAC} onBack={this.handleBack} {...participant} lang={event && event.lang}/>
    }

    return (
      <div className="check-in" onClick={this.setFocus}>
        <Logo/>

        <Typography variant="h3"
                    align="center"
                    className="check-in-headline"
                    style={{ marginBottom: '2rem', textTransform: 'uppercase' }}>
          {t('checkinHeadline', { lng: event.lang })}
        </Typography>

        <Dialog show={dialogOpen}
                onClose={this.resetState}
                title={t('participantNotFound', { lng: event.lang })}
                content={errorMsg}
                buttonText="OK"
                isError={true}
        />

        <form onSubmit={this.handleSubmit}>
          <TextField
            fullWidth
            autoFocus
            name="participantId"
            id="participantId"
            label={t('participantId', { lng: event.lang })}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleInputChange}
            value={participantId}
            inputRef={(el: any) => (this._input = el)}
          />
        </form>

        <Typography variant="button"
                    align="center"
                    className="check-in-headline"
                    style={{ marginTop: '2rem', textTransform: 'uppercase' }}>
          {t('checkinInstructions', { lng: event.lang })}
        </Typography>
      </div>
    )
  }
}

export default withTranslation()(CheckIn)
