import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import green from '@material-ui/core/colors/green';

import { theme } from '../../util/helpers';

interface IDialogProps {
  show: boolean;
  onClose: () => any;
  content: any;
  title?: string;
  buttonText?: string;
  large?: boolean;
  isError?: boolean;
}

export default (props: IDialogProps) => {
  const btnStyle = props.isError ? theme.palette.secondary.main : green['700'];

  return (
    <Dialog
      fullScreen={props.large}
      open={props.show}
      scroll='paper'
      aria-labelledby="rush-dialog"
    >
      {props.title && <DialogTitle id="rush-dialog">{props.title}</DialogTitle>}
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: props.content }}/>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '1.5rem' }}>
        {props.buttonText &&
        <Button size="large"
                variant="contained"
                onClick={props.onClose}
                style={{ background: btnStyle, color: '#fff' }}>
          {props.buttonText}
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
