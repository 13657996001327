import React from 'react';
import { Router } from '@reach/router';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { theme } from '../../util/helpers';

import Home from '../Home';
import CreateEvent from '../Event/Create';
import EditEvent from '../Event/Edit';
import CheckIn from '../CheckIn';
import FourOhFour from '../404';

export default () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Home path='/'/>
        <CreateEvent path='/events/create'/>
        <EditEvent path='/events/:eventId'/>
        <CheckIn path='/check-in/:eventId'/>
        <FourOhFour default/>
      </Router>
    </MuiThemeProvider>
  );
}
