import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'

import { IEvent } from '../../util/types';

interface IEventListProps extends RouteComponentProps {
  events?: IEvent[];
}

export default ({ events }: IEventListProps) => {

  if (!events) {
    return null;
  }

  return (
    <List>
      {events.map((event: IEvent) => {
        return (
          <ListItem key={event.id}
                    button
                    divider
                    style={{ padding: '1rem' }}
                    onClick={() => navigate('/events/' + event.id)}>
            <ListItemText
              color="textPrimary"
              primary={event.name}
            />
            <ListItemSecondaryAction>
              <Button variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => navigate('/check-in/' + event.id)}>
                Open Check-In
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  );
}
