import React from 'react';

interface IErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export default class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {

  state = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  render(): any {
    if (this.state.error) {
      return (
        <details style={{ whiteSpace: 'pre-wrap' }}>
          ERROR: {
            // @ts-ignore
            this.state.error && this.state.error.toString()
          }
          <br/>
          {
            // @ts-ignore
            this.state.errorInfo.componentStack
          }
        </details>
      )
    }

    return this.props.children;
  }
}
