import React from 'react';
import { parse } from 'papaparse';
import { RouteComponentProps, navigate } from '@reach/router';
import { unparse } from 'papaparse';

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dropbox } from 'dropbox';

import ParticipantTable from './ParticipantTable'
import db, { Event, Participant } from '../../util/db';
import { IEvent, IParticipant } from '../../util/types';

import {
  LANGUAGES,
  parseParticipant,
  slugify,
  timestampToHuman,
  exludedReportKeys,
  isOnline,
} from '../../util/helpers';

import { isLocalhost } from '../../serviceWorker'

const testDataUrl = 'https://simplifio-wordpress-static-sites.s3.eu-central-1.amazonaws.com/rush.csv';
const dropBoxAccessToken = 'dJK1Lm6a5jAAAAAAAAAADmuZWohpxVn3Vk9NdkYSfQFkBdy3UySkpmGny0lzVnlY';
// const dropBoxAccessToken = 'k-iSKw0sKRkAAAAAAAAjPoNqoRnGYzvoAncr0b15WB_lcy1dFhswLgLeg5zwk3EH';

// @ts-ignore
const dbx = new Dropbox();
dbx.setAccessToken(dropBoxAccessToken);

interface IEditEventProps extends RouteComponentProps {
  eventId?: number;
}

interface IEditEventState {
  event?: IEvent;
  participants: IParticipant[];
  totalParticipants: number;
  currentParticipantsPage: number;
  pristine: boolean;
  isLoading: boolean;
}

const defaultState = {
  event: undefined,
  participants: [],
  totalParticipants: 0,
  currentParticipantsPage: 0,
  pristine: true,
  isLoading: false,
}

export default class Edit extends React.Component<IEditEventProps, IEditEventState> {
  state: IEditEventState = defaultState;

  async componentDidMount() {
    await this.fetchEvent();
  }

  fetchEvent = async () => {
    const { eventId } = this.props;

    //@ts-ignore
    const event = await Event.get(parseInt(eventId));

    if (event && event.id) {
      const totalParticipants = await Participant.byEventIdCount(event.id);
      return this.setState({
        event,
        totalParticipants,
        pristine: true,
        isLoading: false
      }, () => this.fetchParticipants());
    }

    return navigate('/404');
  }

  fetchParticipants = async () => {
    this.setState({ isLoading: true });
    const { currentParticipantsPage, event } = this.state;

    if (event && event.id) {
      const participants = await Participant.byEventId(event.id, currentParticipantsPage);
      this.setState({ isLoading: false });
      return this.setState({ participants });
    }
  }

  handleFileParseComplete = async ({ data }: any) => {
    const { event } = this.state;

    if (event) {
      const added = data.map(async (item: any) => {
        const participant = parseParticipant(item);

        if (participant) {
          const p = new Participant({ ...participant, eventId: event.id })
          return p.save();
        }
      });

      try {
        await Promise.all(added);
        await this.fetchEvent();
        this.setState({ isLoading: false });
        alert(`Imported ${this.state.totalParticipants} participants`);

      } catch (e) {
        if (e.name === 'ConstraintError') {
          alert('File contains participants which are already in DB!');
          await this.fetchEvent();
          this.setState({ isLoading: false });
        } else {
          alert(e.message);
          await this.fetchEvent();
          this.setState({ isLoading: false });
        }
      }
    }
  }

  handleFileParseError = (error: any, file: any) => {
    this.setState({ isLoading: false });
    alert(error);
  }

  handleChangeFile = async (file: any) => {
    this.setState({ isLoading: true });
    parse(file, {
      header: true,
      error: this.handleFileParseError,
      complete: this.handleFileParseComplete,
    });
  }

  fetchTestData = async () => {
    this.setState({ isLoading: true });

    parse(testDataUrl, {
      download: true,
      header: true,
      error: this.handleFileParseError,
      complete: this.handleFileParseComplete,
    });
  }

  handleInputChange = (e: any) => {
    const { event } = this.state;

    if (event) {
      const key = e.target.name;
      const val = e.target.value;
      return this.setState({ event: { ...event, [key]: val }, pristine: false });
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    const { event, pristine } = this.state;

    if (pristine) {
      return false;
    }

    if (event) {
      const { name, lang, created, isExported, id } = event;
      const data = await db.events.put({ name, lang, created, isExported, id });

      if (data) {
        alert('Successfully edited ' + name + '!');
        this.setState({ isLoading: true });
        await this.fetchEvent();
      }
    }
  }

  handleDelete = async (e: any) => {
    e.preventDefault();

    const { event, participants } = this.state;

    if (event) {
      let msg = 'Sure you want to delete ' + event.name + '?';

      if (!event.isExported && participants.length > 0) {
        msg += ' (Participant-data has NOT been exported yet!)';
      }

      const shouldDelete = confirm(msg);

      if (shouldDelete) {
        this.setState({ isLoading: true });

        // @ts-ignore
        const results = await Event.delete(event.id);

        if (results.every((item: boolean) => !!item)) {
          this.setState({ isLoading: false });

          return navigate('/');
        } else {
          alert('Something went wrong...');
          this.setState({ isLoading: false });
          return navigate('/');
        }
      }
    }
  }

  clearParticipants = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const { event } = this.state;

    if (event) {
      let msg = 'Clear participants for event ' + event.name + '?';

      if (!event.isExported) {
        msg += ' (Participant-data has NOT been exported yet!)';
      }

      const shouldClear = confirm(msg);

      if (shouldClear) {
        this.setState({ isLoading: true });
        // @ts-ignore
        const deleted = await Event.clearParticipants(event.id);

        if (deleted) {
          const deletedParticipants = deleted[0];
          // const deletedProducts = deleted[1].reduce((a: number, b: number) => a + b, 0);

          await this.fetchEvent();
          this.setState({ isLoading: false });
          alert(`Cleared ${deletedParticipants} participants for event ${event.name}!`);
        }
      }
    }
  }

  setExported = async () => {
    const { event } = this.state;

    if (event) {
      const data = await db.events.put({ ...event, isExported: true });
      if (data) {
        await this.fetchEvent();
      }
    }
  }

  createExportData = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { event } = this.state;

    if (event && event.id) {
      const filename = slugify(event.name) + '_' + new Date().getTime() + '.csv';
      const participants = await db.participants.where({ eventId: event.id }).toArray();

      const data = participants
        .filter((p: IParticipant) => !!p.checkinTime)
        .map((item: IParticipant) => {
          let obj = {};
          for (const key in item) {
            if (exludedReportKeys.indexOf(key) === -1) {
              let val = item[key]

              if (key === 'checkinTime' && val) {
                val = timestampToHuman(item[key])
              }

              obj = { ...obj, [key]: val }
            }
          }

          return obj;
        });

      const csv = unparse(data);
      const blob = new Blob([csv], {
        'type': 'application/octet-stream'
      });

      this.setState({ isLoading: true });

      return dbx.filesUpload({ path: '/' + event.name + '/' + filename, contents: blob })
        .then(async (res: any) => {
          await this.setExported();
          alert(`Succesfully exported ${res.name} to Dropbox!`);
        })
        .catch((err: any) => {
          console.log(err);
          alert(`Dropbox error!`)
        })
        .finally(() => this.setState({ isLoading: false }));
    }
  }

  changePage = (e: any, page: number) => {
    this.setState({ currentParticipantsPage: page }, () => this.fetchParticipants());
  }

  render() {
    const { event, participants, pristine, isLoading, totalParticipants, currentParticipantsPage } = this.state;

    if (!event) {
      return <LinearProgress className="edit-event-loader" color="secondary"/>;
    }

    const { name, lang } = event;
    const hasParticipants = totalParticipants > 0;

    return (
      <div className="edit-event">
        {isLoading && <LinearProgress className="edit-event-loader" color="secondary"/>}

        <form className="edit-event-form" onSubmit={this.handleSubmit}>

          <TextField
            fullWidth
            id="event-name"
            label="Event name"
            name="name"
            value={name}
            onChange={this.handleInputChange}
            margin="normal"
            variant="outlined"
            disabled={isLoading}
          />

          <TextField
            select
            fullWidth
            name="lang"
            id="event-lang"
            label="Language"
            value={lang}
            onChange={this.handleInputChange}
            SelectProps={{
              native: true,
            }}
            disabled={isLoading}
            margin="normal"
            variant="outlined">
            {LANGUAGES.map((lang: string, i: number) => {
              return (
                <option key={i} value={lang}>{lang}</option>
              );
            })}
          </TextField>


          {!hasParticipants &&
          <div className="form-section">
            <input
              name="participants-csv"
              id="participants-csv"
              accept=".csv"
              type="file"
              onChange={(e: any) => this.handleChangeFile(e.target.files[0])}
              disabled={!isOnline() || isLoading}
            />
            <label htmlFor="participants-csv" style={{
              background: 'transparent',
              padding: 0,
              width: '100%',
              marginTop: '.8rem'
            }}>
              <Button variant="contained"
                      color="secondary"
                      component="span"
                      size="large"
                      fullWidth disabled={!isOnline() || isLoading}>
                Import participants
              </Button>
              {isLocalhost &&
              <Button variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => this.fetchTestData()}>
                Load test data
              </Button>
              }
            </label>
          </div>
          }

          <Grid container spacing={40} alignItems="center" style={{ marginTop: '1rem' }}>
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <Button
                disabled={isLoading}
                onClick={() => navigate('/')}>
                <Icon className="fas fa-chevron-left" style={{ fontSize: '.8rem', marginRight: '.4rem' }}/> Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={pristine || isLoading}>
                Save
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <Button
                disabled={isLoading}
                onClick={this.handleDelete}>
                Delete <Icon className="fas fa-trash" style={{ fontSize: '.8rem', marginLeft: '.4rem' }}/>
              </Button>
            </Grid>
          </Grid>
        </form>

        {hasParticipants &&
        <div className="participants">
          <ExpansionPanel style={{ marginTop: '2rem' }}>
            <ExpansionPanelSummary expandIcon={<Icon fontSize="small" className="fas fa-chevron-down"/>}>
              <Grid container spacing={40} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="button">Participants <small>({totalParticipants})</small></Typography>
                </Grid>

                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button variant="contained"
                          size="small"
                          disabled={isLoading}
                          onClick={this.createExportData}>
                    Export <Icon className="fas fa-file-download" style={{
                    marginLeft: '.4rem',
                    fontSize: '0.8125rem'
                  }}/>
                  </Button>
                  <Button variant="contained"
                          size="small"
                          color="secondary"
                          disabled={isLoading}
                          onClick={this.clearParticipants}>
                    Clear <Icon className="fas fa-trash" style={{ marginLeft: '.4rem', fontSize: '0.8125rem' }}/>
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ParticipantTable totalCount={totalParticipants}
                                onChangePage={this.changePage}
                                data={participants}
                                isLoading={isLoading}
                                currentPage={currentParticipantsPage}/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
      </div>
    );
  }
}
