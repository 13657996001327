import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

import EventList from '../Event/List';
import { Event } from '../../util/db';
import { IEvent } from '../../util/types';

import Logo from '../Logo';

interface IHomeProps extends RouteComponentProps {
}

interface IHomeState {
  events?: IEvent[];
}

class Home extends React.Component<IHomeProps, IHomeState> {

  state = { events: [] }

  async componentDidMount() {
    const events = await Event.all();
    this.setState({ events });
  }

  render() {
    const { events } = this.state;

    return (
      <div className='home'>
        <Logo/>
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <Button variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth={true}
                    onClick={() => navigate('events/create')}>
              Create a new event
            </Button>
            <Button variant="contained"
                    size="small"
                    color="primary"
                    fullWidth={false}
                    style={{
                      display: 'block',
                      margin: '1rem auto'
                    }}
                    onClick={() => window.location.reload()}>
              Reload
            </Button>
          </Grid>

          <Grid item xs={12}>
            <EventList events={events}/>
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default Home;
